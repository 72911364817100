<template>
  <div class="flex flex-grow items-start fade-in">
    <div
        class="relative top-0 left-0 w-full h-full">
      <div class="background-wrapper background-wrapper--step2">
        <BackgroundImage class="background" />
      </div>
    </div>
    <div class="w-full mx-8 md:w-5/12 md:mt-32 text-center">
      <h2 class="uppercase text-2xl mb-2">
        Loading...
      </h2>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoginUserMixin from '@/mixins/LoginUserMixin';
import BackgroundImage from '@/assets/new-report-bg.svg?inline';

export default {
  name: 'Callback',
  components: { BackgroundImage },
  computed: {
    ...mapGetters([
      'idTokenPayload',
      'isAuthenticated',
      'organizationIsInactive',
      'reports',
    ]),
  },
  created() {
    this.$store.dispatch('validateFromHash');
  },
  mixins: [LoginUserMixin],
};
</script>

<style scoped>
.background {
  @apply max-w-screen-lg w-full h-full;
  animation: spin 120s linear infinite;
  transform-origin: center center;
}
.background-wrapper--step2 {
  transform: translateY(20%) scale(1);
}
</style>
