<template>
  <transition name="slide-down" mode="out-in">
    <div v-if="!isEmailSent"
         class="login-wrapper"
         key="form">
      <div class="flex flex-col mb-4">
        <h1 class="font-medium text-3xl mb-2 uppercase">
          Forgot password
        </h1>
        <h2 class="text-gray-200">
          Type in your email below, and we'll send you a link to reset your password.
        </h2>
      </div>
      <div class="mb-8">
        <TextInput id="email"
                   type="email"
                   label="Email"
                   placeholder="Email address..."
                   v-model="email"
                   @keyup.enter.native="handleForgotPassword"/>
      </div>
      <Button
        type="primary"
        @onClick="handleForgotPassword"
        :disabled="!email">
        Send
      </Button>
      <div v-if="globalErrors.length" class="mt-8">
        <div
          class="text-red-600"
          v-for="(error, index) in globalErrors"
          :key="`error${index}`">
          {{ error }}
        </div>
      </div>
    </div>
    <div v-else
         class="login-wrapper"
         key="message">
      <h1 class="font-medium text-3xl mb-2 uppercase">
        Email sent
      </h1>
      <h2 class="text-gray-200 mb-6">
        A password reset email has been sent to <span class="text-orange-400">{{ email }}</span>.
      </h2>
      <Button type="text-small"
              class="text-gray-100"
              @onClick="redirectToLogin">
        <template v-slot:iconLeft>
          <ArrowLeft class="text-gray-300"/>
        </template>
        Back to Login
      </Button>
    </div>
  </transition>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import TextInput from '@/components/TextInput.vue';
import Button from '@/components/Button.vue';
import ArrowLeft from '@/assets/arrow_left.svg?inline';

export default {
  name: 'ForgotPassword',
  components: {
    TextInput,
    Button,
    ArrowLeft,
  },
  computed: {
    ...mapGetters(['isLoading', 'globalErrors']),
  },
  data() {
    return {
      requiredFields: ['email'],
      email: '',
      isEmailSent: false,
    };
  },
  created() {
    this.clearGlobalErrors();
  },
  methods: {
    ...mapActions(['requestForgotPassword', 'clearGlobalErrors']),
    handleForgotPassword() {
      if (this.email) {
        this.requestForgotPassword(this.email)
          .then(() => {
            this.isEmailSent = true;
          });
      }
    },
    redirectToLogin() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
<style scoped>
.login-wrapper {
  @apply mt-32 mx-auto w-full;
  max-width: 300px;
}

.slide-down-enter-active, slide-down-leave-active {
 transition: all .2s ease-in-out;
}

.slide-down-enter, .slide-down-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
