<template>
  <transition name="slide-down" mode="out-in">
    <div class="flex justify-center items-center h-screen">
      <div class="flex-col w-1/4 mb-4">
        <TextInput
          class="mb-4"
          placeholder="New Password"
          v-model="inputText"
          autofocus
        />
        <Button
          type="primary"
          class="text-gray-300 mx-auto"
          @onClick="submit"
          @keyup.enter="submit"
        >
          Continue
        </Button>
      </div>
    </div>
  </transition>
</template>
<script>
import TextInput from '@/components/TextInput.vue';
import Button from '@/components/Button.vue';
import { mapActions } from 'vuex';

export default {
  name: 'ResetPassword',
  components: {
    Button,
    TextInput,
  },
  data() {
    return {
      inputText: '',
    };
  },
  methods: {
    ...mapActions(['updatePassword']),
    submit() {
      this.updatePassword(this.inputText);
    },
  },
};
</script>
