<template>
  <transition name="slide-down" mode="out-in">
    <div
      class="login-wrapper w-11/12 sm:w-5/12 lg:w-1/3"
      key="form">
      <div class="flex flex-col mb-4">
        <h1 class="font-medium text-3xl mb-2 uppercase">
          Register
        </h1>
        <h2 class="text-gray-200">
          Your password must be at least 8 characters long, include a lower case character (a-z),
          an upper case character (A-Z), a number (0-9), and a special character (!@#$%^&*)
        </h2>
      </div>
      <div class="mb-8">
        <TextInput id="password"
                   type="password"
                   label="Password"
                   placeholder=""
                   v-model="password"
                   @keyup.enter.native="handleRegistration"/>
      </div>
      <div class="mb-8">
        <TextInput id="confirm-password"
                   type="password"
                   label="Confirm Password"
                   placeholder=""
                   v-model="passwordConfirmation"
                   @keyup.enter.native="handleRegistration"/>
      </div>
      <Button
        :inFlight="isLoading"
        type="primary"
        @onClick="handleRegistration"
        :disabled="!password || !passwordConfirmation">
        Submit
      </Button>
      <div v-if="globalErrors.length" class="mt-8">
        <ErrorMessage
          v-for="(error, index) in globalErrors"
          :key="`error${index}`"
          :error="error">
        </ErrorMessage>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import Button from '@/components/Button.vue';
import LoginUserMixin from '@/mixins/LoginUserMixin';
import TextInput from '@/components/TextInput.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';

export default {
  name: 'Register',
  components: {
    Button,
    ErrorMessage,
    TextInput,
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'globalErrors',
      'idTokenPayload',
      'isAuthenticated',
      'organizationIsInactive',
      'reports',
    ]),
  },
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      registrationToken: '',
      emailAddress: '',
    };
  },
  beforeMount() {
    localStorage.clear();
  },
  created() {
    this.clearGlobalErrors();
    const { token, email } = this.$route.query;

    if (token) {
      this.registrationToken = token;
    }

    if (email) {
      this.emailAddress = decodeURIComponent(email);
    }
  },
  mixins: [LoginUserMixin],
  methods: {
    ...mapActions([
      'requestForgotPassword',
      'clearGlobalErrors',
      'register',
      'getUser',
      'fetchReports',
    ]),
    handleRegistration() {
      const {
        password, passwordConfirmation, registrationToken, emailAddress,
      } = this;

      this.register({
        password, passwordConfirmation, registrationToken, emailAddress,
      });
    },
  },
};
</script>
<style scoped>
.login-wrapper {
  @apply mt-32 mx-auto;
}

.slide-down-enter-active, slide-down-leave-active {
 transition: all .2s ease-in-out;
}

.slide-down-enter, .slide-down-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
